import useSearchComics from "@/api/hooks/useSearchComics"
import Pagination from "@/components/core/Pagination"
import SEO from "@/components/core/SEO"
import Badge, { BadgeIconContent } from "@/components/shared/Badge"
import Banner from "@/components/shared/Banner"
import { BeBanner } from "@/components/shared/Banner/BeBanner"
import Card from "@/components/shared/Card"
import CardSkeleton from "@/components/shared/Card/CardSkeleton"
import Container from "@/components/shared/Container"
import Popover from "@/components/shared/Popover"
import ToolTip from "@/components/shared/ToolTip"
import Typography from "@/components/shared/Typography"
import SearchInput from "@/components/shared/inputs/SearchInput"
import { ROUTES } from "@/conf"
import { getStateOfCartoon } from "@/utils/comics"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"
import { AdPosition } from "swagger/models"

const HomePage = () => {
  const { t } = useTranslation("common")
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [scrollToTop, setScrollToTop] = useState(false)

  const itemsPerPage = 9

  const scrollToRef = useRef<HTMLDivElement>(null)

  const { comicsList, isLoading } = useSearchComics(
    { fulltext: searchQuery },
    currentPage - 1, // Adjust for zero-based page index
    itemsPerPage,
    ["state,asc", `created_at,desc`],
  )

  const popoverOptions = ["Nejnovější", "Nejstarší"]

  useEffect(() => {
    if (scrollToRef.current && scrollToTop && !isLoading) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" })
      setScrollToTop(false)
    }
  }, [currentPage, isLoading, scrollToTop])

  return (
    <Container className="pb-16 pt-4">
      <SEO titleSuffix={false} />

      <Banner className="mb-[68px] mt-16 hidden sm:block" />

      <div
        className="mb-[44px] flex flex-col justify-start  gap-8 sm:flex-row sm:items-center sm:justify-between sm:gap-0 "
        ref={scrollToRef}
      >
        <div className="flex flex-1 items-end  justify-between sm:justify-start ">
          <div className="flex gap-x-2">
            <Typography type="h1" tag="h1">
              {t("cartoons")}
            </Typography>
            <ToolTip text={t("comicsListTooltip")}>
              <div className="rounded-full bg-primary-500 px-3 py-[9px]">
                <div className="h-[14px] w-[8.44px]">
                  <Image src="/assets/images/question.svg" width={8.44} height={14} alt={t("comicsListTooltip")} />
                </div>
              </div>
            </ToolTip>
          </div>
        </div>
        <SearchInput mainClassName="full-w sm:max-w-sm" onSearch={(query) => setSearchQuery(query)} />
      </div>

      <div className="flex flex-col gap-y-4">
        <div className="grid grid-cols-1 gap-4 pb-16 sm:grid-cols-2 md:grid-cols-3 lg:gap-8">
          <>
            {isLoading ? (
              Array(9)
                .fill("x")
                .map((_, idx) => {
                  return <CardSkeleton key={idx} />
                })
            ) : (
              <>
                {comicsList?.content.map((i, idx) => {
                  return (
                    <>
                      {idx === 3 && <Banner className="mb-5 mt-5 hidden max-sm:block" />}
                      {idx === 6 && (
                        <div className="mb-5 mt-5 hidden max-sm:block">
                          <BeBanner position={AdPosition.HOME} />
                        </div>
                      )}
                      <Card
                        title={i.name}
                        subtitle={i?.location?.name || ""}
                        link={`${ROUTES.cartoonDetail}/${i.slug}`}
                        image={i?.imageUrl || "/assets/images/cartoon.png"}
                        key={i.comicId}
                        state={i.state}
                      >
                        {getStateOfCartoon(i.state, i.createdAt)}
                        <div className="absolute right-4 top-4 flex flex-col items-end justify-end">
                          <Badge className="mb-2" inverse>
                            <BadgeIconContent
                              crown={i?.game?.claimed}
                              location={i?.location?.claimed}
                              help={i?.bonusQuestion?.claimed}
                            />
                          </Badge>
                          <Badge
                            icon={<Image src="/assets/images/grosak.svg" width={20} height={20} alt="" />}
                            variant="reward"
                            inverse
                          >
                            {`${i.gainedGroshak} / ${i.maxOfGroshak}`}
                          </Badge>
                        </div>
                      </Card>
                    </>
                  )
                })}
              </>
            )}
          </>
        </div>
      </div>

      <div className="block max-sm:hidden">
        <BeBanner position={AdPosition.HOME} />
      </div>

      <div className="mt-16 border-t-[1px] border-primary-500" />

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil((comicsList?.totalElements ?? 0) / itemsPerPage)}
        onPageChange={(page: number) => {
          setCurrentPage(page)
          setScrollToTop(true)
        }}
      />
    </Container>
  )
}

export default HomePage
